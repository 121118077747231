import React from 'react';

import { getHomePage } from './home.query';

import Hero from '../../components/Hero/Hero';
import Promo from '../../components/Promo/Promo';

const HomePage = () => {
  const data = getHomePage();

  const {
    restaurantPromoButtonText,
    restaurantPromoDescription,
    restaurantPromoImage,
    pubPromoButtonText,
    pubPromoDescription,
    pubPromoImage,
    apartmentsPromoButtonText,
    apartmentsPromoDescription,
    apartmentsPromoImage,
    sliderImages,
  } = data.allWordpressPage.edges[0].node.acf;

  const { pubImage, restaurantImage, apartmentsImage } = data;

  return (
    <>
      <Hero slides={sliderImages} />

      <Promo
        logo={restaurantImage.childImageSharp.fixed}
        image={restaurantPromoImage.localFile.childImageSharp.fluid}
        paragraph={restaurantPromoDescription}
        buttonText={restaurantPromoButtonText}
        buttonLink="/restauracja/"
      />

      <Promo
        mirrored
        logo={pubImage.childImageSharp.fixed}
        image={pubPromoImage.localFile.childImageSharp.fluid}
        paragraph={pubPromoDescription}
        buttonText={pubPromoButtonText}
        buttonLink="/pub/"
      />

      <Promo
        logo={apartmentsImage.childImageSharp.fixed}
        image={apartmentsPromoImage.localFile.childImageSharp.fluid}
        paragraph={apartmentsPromoDescription}
        buttonText={apartmentsPromoButtonText}
        buttonLink="/apartamenty/"
      />
    </>
  );
};

export default HomePage;
