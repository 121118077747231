import { useStaticQuery, graphql } from 'gatsby';

// eslint-disable-next-line import/prefer-default-export
export const getHomePage = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { id: { eq: "0a3577a7-fb61-5dce-9c42-0358b1e24844" } }) {
        edges {
          node {
            acf {
              restaurantPromoButtonText
              restaurantPromoDescription
              restaurantPromoHeader
              restaurantPromoImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              pubPromoButtonText
              pubPromoDescription
              pubPromoHeader
              pubPromoImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              apartmentsPromoButtonText
              apartmentsPromoDescription
              apartmentsPromoHeader
              apartmentsPromoImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              sliderHeading
              sliderImages {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 4000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      pubImage: file(relativePath: { eq: "pub-logo.png" }) {
        childImageSharp {
          fixed(height: 110) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      restaurantImage: file(relativePath: { eq: "restauracja-logo.png" }) {
        childImageSharp {
          fixed(width: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      apartmentsImage: file(relativePath: { eq: "apartamenty-logo.png" }) {
        childImageSharp {
          fixed(width: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
